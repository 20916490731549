































































































































































































































































































































































































































.list-templates {
  display: flex;
  .template-item {
    margin-bottom: 20px;
    .card {
      height: 100%;
      .card-body {
        display: flex;
        flex-direction: column;
        .template-description {
          flex: 1 0 auto;
        }
      }
    }
  }
  .template-preview {
    border-radius: 5px;
    max-height: 230px;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
}
